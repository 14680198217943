<template>
  <div class="flex flex-col w-full">
    <div v-if="!errorPage">
      <div class="flex flex-col w-full h-auto">
        <Card class="p-5 w-full">
          <div class="flex">
            <div
              class="flex flex-grow mt-6 mb-6"
              style="justify-content: flex-end"
            >
              <Button class="bg-dynamicBackBtn text-white" @click="createTemplate()">
                New Template
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="viewKPAModal"
              >
                KPA Types
              </Button>
            </div>
          </div>
          <template v-slot:footer>
            <CardFooter showcalendar reloadcard @searchResult="handleSearch($event)" @reload="reload()" />
          </template>
        </Card>
      </div>

      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <div class="flex flex-wrap w-full my-1">
          <div class="w-full mt-3">
            <Table
              :headers="templateBHeader"
              :items="templates"
              v-if="templates.length > 0 || loadingTables"
              class="py-2 px-3"
              :has-checkbox="false"
              :has-number="true"
              :loading="loadingTables"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
            >
              <template v-slot:item="{ item }">
                <div v-if="item.appraisal_cycle">
                  <span v-if="item.data.appraisal_cycle"> --- </span>
                  <span v-else class="p-2"> --- </span>
                </div>
                <div v-else-if="item.kpa_types">
                  <div class="w-auto" v-if="item.data.kpa_types.length > 0">
                    <Menu right top="-400" class="p-0" margin="23">
                      <template v-slot:title>
                        <Badge
                          :label="item.data.kpa_types.length.toString()"
                          variant="primary"
                          background-color="rgba(234, 60, 83, 0.15)"
                          style="cursor: pointer"
                          color="#EA3C53"
                        />
                      </template>
                      <div style="width: 250px; min-height: 80px" class="p-4">
                        <div class="mb-2 font-bold text-base">KPA Types</div>
                        <div
                          class="border border-dashed mb-4"
                          style="border-width: 0.5px"
                        />
                        <div
                          class="flex py-1 font-semibold text-base"
                          v-for="(kpa, index) in item.data.kpa_types"
                          :key="index"
                        >
                          {{ kpa.name }}
                        </div>
                      </div>
                    </Menu>
                  </div>
                  <span v-else class="p-2"> --- </span>
                </div>

                <div v-else-if="item.locationIds">
                  <div v-if="item.data.applyToAllLocation">
                    <span class="p-2"> All </span>
                  </div>
                  <div v-else>
                    <div class="w-auto" v-if="item.data.locationIds.length > 0">
                      <Menu right top="-400" class="p-0" margin="23">
                        <template v-slot:title>
                          <Badge
                            :label="item.data.locationIds.length.toString()"
                            variant="primary"
                            background-color="rgba(33, 118, 255, 0.15)"
                            color="#2176FF"
                          />
                        </template>
                        <div style="width: 250px; min-height: 80px" class="p-4">
                          <div class="mb-2 font-bold text-base">Locations</div>
                          <div
                            class="border border-dashed mb-4"
                            style="border-width: 0.5px"
                          />
                          <div
                            class="flex py-1 font-semibold text-base"
                            v-for="(location, index) in item.data.locationIds"
                            :key="index"
                          >
                            {{ getLocation(location) }}
                          </div>
                        </div>
                      </Menu>
                    </div>
                    <span v-else class="p-2"> --- </span>
                  </div>
                </div>

                <div v-else-if="item.jobLevelIds">
                  <div v-if="item.data.applyToAllJobLevel">
                    <span class="p-2"> All </span>
                  </div>
                  <div v-else>
                    <div class="w-auto" v-if="item.data.jobLevelIds.length > 0">
                      <Menu right top="-400" class="p-0" margin="23">
                        <template v-slot:title>
                          <Badge
                            :label="item.data.jobLevelIds.length.toString()"
                            variant="primary"
                            background-color="rgba(233, 147, 35, 0.15)"
                            color="#E99323"
                          />
                        </template>
                        <div style="width: 250px; min-height: 80px" class="p-4">
                          <div class="mb-2 font-bold text-base">Job Levels</div>
                          <div
                            class="border border-dashed mb-4"
                            style="border-width: 0.5px"
                          />
                          <div
                            class="flex py-1 font-semibold text-base"
                            v-for="(levels, index) in item.data.jobLevelIds"
                            :key="index"
                          >
                            {{ getLevels(levels) }}
                          </div>
                        </div>
                      </Menu>
                    </div>
                    <span v-else class="p-2"> --- </span>
                  </div>
                </div>

                <div v-else-if="item.functionIds">
                  <div v-if="item.data.applyToAllFunction">
                    <span class="p-2"> All </span>
                  </div>
                  <div v-else>
                    <div class="w-auto" v-if="item.data.functionIds.length > 0">
                      <Menu right top="-400" class="p-0" margin="23">
                        <template v-slot:title>
                          <Badge
                            :label="item.data.functionIds.length.toString()"
                            variant="primary"
                            background-color="rgba(73, 1, 102, 0.15)"
                            color="#490166"
                          />
                        </template>
                        <div style="width: 250px; min-height: 80px" class="p-4">
                          <div class="mb-2 font-bold text-base">Functions</div>
                          <div
                            class="border border-dashed mb-4"
                            style="border-width: 0.5px"
                          />
                          <div
                            class="flex py-1 font-semibold text-base"
                            v-for="(func, index) in item.data.functionIds"
                            :key="index"
                          >
                            {{ getFunctionName(func) }}
                          </div>
                        </div>
                      </Menu>
                    </div>
                    <span v-else class="p-2"> --- </span>
                  </div>
                </div>

                <div v-else-if="item.designationIds">
                  <div
                    class="w-auto"
                    v-if="item.data.designationIds.length > 0"
                  >
                    <Menu right top="-400" class="p-0" margin="23">
                      <template v-slot:title>
                        <Badge
                          :label="item.data.designationIds.length.toString()"
                          variant="primary"
                          background-color="rgba(19, 181, 106, 0.15)"
                          color="#13B56A"
                        />
                      </template>
                      <div style="width: 250px; min-height: 80px" class="p-4">
                        <div class="mb-2 font-bold text-base">Designations</div>
                        <div
                          class="border border-dashed mb-4"
                          style="border-width: 0.5px"
                        />
                        <div
                          class="flex py-1 font-semibold text-base"
                          v-for="(designation, index) in item.data
                            .designationIds"
                          :key="index"
                        >
                          {{ getDesignationName(designation) }}
                        </div>
                      </div>
                    </Menu>
                  </div>
                  <span v-else class="p-2"> All </span>
                </div>

                <div v-else-if="item.status" class="flex flex-wrap">
                  <div class="flex w-auto">
                    <div
                      class="mb-2 capitalize"
                      style="min-width: 118px; height: 28px; padding: 5px 0px"
                    >
                      <Badge
                        :label="item.data.status"
                        v-if="item.data.status === 'active'"
                        variant="primary"
                        background-color="rgba(19, 181, 106, 0.15)"
                        color="#13B56A"
                      />
                      <Badge
                        :label="item.data.status"
                        v-if="item.data.status === 'draft'"
                        variant="primary"
                        background-color="rgba(135, 142, 153, 0.15)"
                        color="#EA3C53"
                      />
                      <Badge
                        :label="item.data.status"
                        v-if="item.data.status === 'inactive'"
                        variant="primary"
                        background-color="rgba(233, 147, 35, 0.15)"
                        color="#E99323"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex" v-else-if="item.id">
                  <Menu left top="-50" class="p-0" margin="30">
                    <template v-slot:title>
                      <icon
                        icon-name="more_icon"
                        size="xsm"
                        class="-mt-1"
                        style="padding: 3px 10px; width: 36px; height: 36px"
                      />
                    </template>
                    <div style="width: 158px; height: 80px" class="py-3 px-2">
                      <div
                        class="flex py-1 px-3 cursor-pointer"
                        @click="viewTemplate(item)"
                      >
                        <icon
                          icon-name="edit"
                          class-name="text-blueCrayola mr-2"
                          size="xs"
                        />
                        <p class="pt-1" style="font-size: 14px">
                          View and Edit
                        </p>
                      </div>
                      <div
                        class="flex px-3 py-1 more h-8 cursor-pointer"
                        @click="initiateDelete(item.data.id)"
                      >
                        <icon
                          icon-name="delete"
                          class-name="text-desire mr-2"
                          size="xs"
                        />
                        <p class="pt-1" style="font-size: 14px">Delete</p>
                      </div>
                    </div>
                  </Menu>
                </div>
              </template>
            </Table>

            <div class="flex flex-wrap w-full my-1 p-5 justify-center" v-else>
              <div class="my-4 w-full flex py-6 justify-center">
                <img src="@/assets/images/empty_template.png" alt="" />
              </div>
              <div class="w-3/5 flex justify-center">
                <p class="text-base mt-5 text-center">
                  You have not setup a performance template. You need to create
                  one with the button below, setting up the name and attaching
                  the template to as many KPA types you wish to.
                </p>
              </div>

              <div
                class="text-flame font-semibold flex w-full justify-center my-4 p-2 text-base cursor-pointer"
              >
                <icon icon-name="icon-plus" class="mr-2" size="xsm" />
                New Template
              </div>
            </div>
          </div>
        </div>
      </div>

      <ValidationObserver v-slot="{ handleSubmit }">
        <RightSideBar
          v-if="kpaModal"
          @close="closeKPAModal"
          close-button
          button-class="bg-dynamicBackBtn text-white"
        >
          <template v-slot:title>
            <p class="text-darkPurple">KPA Types</p>
          </template>
          <template v-slot:subtitle>
            <p class="font-semibold text-darkPurple my-4 text-base">
              Add KPA types used in your templates here. Note that you will not
              be able to remove a KPA type, once it has been applied in a
              template.
            </p>
          </template>
          <div class="flex flex-col font-semibold my-4 text-base">
            KPA Types
          </div>
          <div
            class="flex w-full my-4 label"
            v-for="(k, index) in kpaTypes"
            :key="index"
          >
            <c-text
              v-model="k.name"
              placeholder="Enter KPA Type"
              class="w-11/12"
              :rules="['required']"
              label="Kpa Name"
              :disabled="k.saved"
            />
            <div class="flex w-1/12 h-10">
              <div
                v-if="k.saved"
                class="cursor-pointer flex"
                @click="handleEditKPA(k)"
              >
                <icon
                  icon-name="icon-edit"
                  size="xms"
                  class="mt-1 mx-4 text-blueCrayola"
                />
              </div>
              <div
                class="flex cursor-pointer"
                v-else
                @click="
                  k.edit ? handleSubmit(editKPA(k)) : handleSubmit(saveKPA(k))
                "
              >
                <icon
                  icon-name="save"
                  class="mt-1 mx-4 text-blueCrayola"
                  size="xms"
                  style="width: 0.8rem"
                />
              </div>
              <div
                class="flex cursor-pointer"
                @click="handleKpaDelete(k, index)"
              >
                <icon icon-name="icon-trash" class="mt-1" size="xs" />
              </div>
            </div>
          </div>
          <div
            class="text-flame font-semibold flex w-full my-4 p-2 text-base cursor-pointer"
            @click="handleCreateKPA()"
          >
            <icon icon-name="icon-plus" class="mr-2" size="xsm" />
            Add KPA Type
          </div>
        </RightSideBar>
      </ValidationObserver>

      <Modal v-if="dialogDel">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <p class="font-bold ml-4 my-5 text-center">
              Are you sure you want to delete this Template?
            </p>
            <div class="flex">
              <Button class="bg-dynamicBackBtn text-white" @click="deleteTemplate">
                Yes
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="dialogDel = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </div>

    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Button from "@/components/Button";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";
import Icon from "@/components/Icon";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "PerformanceTemplate",
  components: {
    ValidationObserver,
    Button,
    Badge,
    RightSideBar,
    ErrorPage,
    CText,
    Table,
    Card,
    CardFooter,
    Icon,
    Modal,
    Menu,
  },
  data() {
    return {
      category: [
        {
          name: "Allow functional managers to edit templates",
          radioName: "employee",
          value: "",
        },
        {
          name: "Allow functional managers to adjust weight",
          radioName: "employee",
          value: "",
        },
        { name: "Both", radioName: "employee", value: "" },
      ],
      dialogDel: false,
      loading: true,
      templateModal: false,
      kpaModal: false,
      errorPage: false,
      loadingTables: true,
      templateBHeader: [
        { title: "Template Title", value: "title" },
        { title: "linked goals & Agreements", value: "appraisal_cycle" },
        { title: "KPA Types", value: "kpa_types" },
        { title: "Locations", value: "locationIds" },
        { title: "Functions", value: "functionIds" },
        { title: "Job Levels", value: "jobLevelIds" },
        { title: "Designations", value: "designationIds" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      importTemplate: false,
      prevTemplate: false,
      templateTitle: "",
      templates: [],
      optionsKPA: [],
      kpaTypes: [],
      locations: [],
      jobLevels: [],
      functions: [],
      designations: [],
      deleteId: "",
      docCycle: "doc-cycle",
      moreIcon: "more_icon",
      disableSave: true,
      searchQuery: '',
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTables = true;
      const search = this.searchQuery ? `&search=${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getTemplates(`?${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `?search=${value}`;
      this.loadingTables = true;
      this.getTemplates(this.searchQuery);
    },

    async handleKpaDelete(k, index) {
      try {
        await this.$handlePrivilege("performance", "deleteKpaTypes");
        this.deleteKPA(k, index);
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async handleEditKPA(k) {
      try {
        await this.$handlePrivilege("performance", "editKpaTypes");
        this.openEdit(k);
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async handleCreateKPA() {
      try {
        await this.$handlePrivilege("performance", "addNewKpaType");
        this.createKPA();
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async viewKPAModal() {
      try {
        await this.$handlePrivilege("performance", "viewKpaTypes");
        this.kpaModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async createTemplate() {
      try {
        await this.$handlePrivilege(
          "performance",
          "createNewPerformanceTemplates"
        );
        this.$router.push({
          name: "TemplateSettings",
          params: { id: "new" },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async viewTemplate(item) {
      try {
        await this.$handlePrivilege("performance", "viewTemplateUsage");
        this.$router.push({
          name: "TemplateSettings",
          params: { id: item.data.id },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    initiateDelete(id) {
      this.dialogDel = true;
      this.deleteId = id;
    },

    deleteTemplate() {
      this.$_deletePerfomanceTemplate(this.deleteId)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.dialogDel = false;
          this.loading = true;
          this.getTemplates();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    saveKPA(kpa) {
      let payload = {};
      const indexedKPA = kpa;

      payload = {
        orgId: kpa.orgId,
        name: kpa.name,
      };

      indexedKPA.saved = true;

      this.$_createKPAtypes(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getKPATypes();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
          indexedKPA.saved = false;
        });
    },

    editKPA(kpa) {
      let payload = {};
      const indexedKPA = kpa;

      payload = {
        id: kpa.id,
        orgId: kpa.orgId,
        request: {
          name: kpa.name,
        },
      };

      indexedKPA.saved = true;

      this.$_editKPAtypes(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
          indexedKPA.saved = false;
        });
    },

    createKPA() {
      this.kpaTypes.push({
        id: null,
        name: "",
        orgId: this.$orgId,
        saved: false,
        edit: false,
      });
    },

    openEdit(kpa) {
      const indexedKPA = kpa;
      indexedKPA.saved = false;
      indexedKPA.edit = true;
    },

    deleteKPA(kpa, index) {
      if (kpa.id) {
        this.$_deleteKPAtypes(kpa.id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        });
      }
      this.kpaTypes.splice(index, 1);
    },

    closeKPAModal() {
      this.kpaModal = false;
      this.getKPATypes();
    },

    normalizeKPA(kpaTypes) {
      this.kpaTypes = [];
      this.optionsKPA = [];

      kpaTypes.filter((kpa) => {
        this.kpaTypes.push({
          id: kpa.id,
          orgId: kpa.orgId,
          name: kpa.name,
          saved: true,
          edit: true,
        });
        return {};
      });

      kpaTypes.filter((kpa) => {
        this.optionsKPA.push({
          id: kpa.id,
          name: kpa.name,
        });
        return {};
      });

      if (kpaTypes.length === 0) {
        this.kpaTypes.push({
          id: null,
          orgId: this.$orgId,
          name: "",
          saved: false,
          edit: false,
        });
      }
    },

    getKPATypes() {
      this.$_getKPAtypes().then((response) => {
        const kpaTypes = response.data.KpaType;
        this.normalizeKPA(kpaTypes);
      });
    },

    getLocation(id) {
      let name = "";
      this.locations.filter((location) => {
        if (location.id === id) {
          name = location.name;
        }
        return {};
      });
      return name;
    },

    getLevels(id) {
      let name = "";
      this.jobLevels.filter((levels) => {
        if (levels.id === id) {
          name = levels.name;
        }
        return {};
      });
      return name;
    },

    getFunctionName(id) {
      let name = "";
      this.functions.filter((func) => {
        if (func.id === id) {
          name = func.name;
        }
        return {};
      });
      return name;
    },

    getDesignationName(id) {
      let name = "";
      this.designations.filter((designation) => {
        if (designation.id === id) {
          name = designation.name;
        }
        return {};
      });
      return name;
    },

    getLocations() {
      this.$_getHumanarLocations("").then((response) => {
        this.locations = [];
        response.data.outlets.forEach((outlet) => {
          this.locations.push({
            id: outlet.id,
            name: outlet.name,
          });
        });
      });
    },

    getJobLevels() {
      this.$_getLevels().then((response) => {
        response.data.levels.forEach((level) => {
          this.jobLevels.push({
            id: level.id,
            name: level.name,
          });
        });
      });
    },

    getFunctions() {
      this.$_getFunctionKpis("").then((response) => {
        response.data.functions.forEach((f) => {
          this.functions.push({
            id: f.id,
            name: f.name,
          });
        });
      });
    },

    getDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.forEach((designation) => {
          this.designations.push({
            id: designation.id,
            name: designation.name,
          });
          return {};
        });
      });
    },

    getTemplates(params) {
      let templatesArr = [];
        this.templates = [];

      this.$_getPerfomanceTemplate(params).then((response) => {
        templatesArr = response.data.PerformanceTemplate;
         this.metaData = response.data.meta;
        this.loading = false;

        templatesArr.forEach((template) => {
          this.templates.push({
            appraisal_cycle: template.appraisal_cycle,
            ceo_moderation_end: template.ceo_moderation_end,
            ceo_moderation_start: template.ceo_moderation_start,
            enable_moderation: template.enable_moderation,
            functional_moderation_end: template.functional_moderation_end,
            functional_moderation_start: template.functional_moderation_start,
            id: template.id,
            kpa_types: template.kpa_types,
            performance_template_status: template.performance_template_status,
            template_type: template.template_type,
            title: template.title,
            status: template.status,
            designationIds: template.appliedToPerormance[0].designationIds,
            functionIds: template.appliedToPerormance[0].functionIds,
            jobLevelIds: template.appliedToPerormance[0].jobLevelIds,
            locationIds: template.appliedToPerormance[0].locationIds,
            applyToAllLocation:
              template.appliedToPerormance[0].applyToAllLocation,
            applyToAllFunction:
              template.appliedToPerormance[0].applyToAllFunction,
            applyToAllDesignation:
              template.appliedToPerormance[0].applyToAllDesignation,
            applyToAllJobLevel:
              template.appliedToPerormance[0].applyToAllJobLevel,
          });
        });

          this.loadingTables = false;
      });
    },

    reload() {
      this.loadingTables = true;
      this.getTemplates('');
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performance",
        "accessPerformanceTemplateSettings"
      );
      this.getKPATypes();
      this.getTemplates('');
      this.getLocations();
      this.getJobLevels();
      this.getFunctions();
      this.getDesignations();
    } catch (error) {
      this.errorPage = true;
    }
  },
};
</script>

<style>
.label label {
  display: none;
}
</style>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
.tempB_footer {
  height: 122px;
  padding: 8px;
}
.card-search {
  width: 320px;
  height: 40px;
}
.card-search input {
  width: 100%;
}
</style>
