<template>
  <div class="flex w-full flex-col">
    <div v-if="!errorPage">
      <div class="w-full h-auto">
        <Card class="w-full flex flex-col">
          <div class="w-full flex p-3"></div>
          <div class="flex mt-6 mb-6 px-6 w-full">
            <p class="flex flex-grow" />
            <Button class="bg-dynamicBackBtn text-white" @click="createModeration()">
              Create New
            </Button>
          </div>
          <template v-slot:footer>
            <CardFooter
              class="pt-2 pb-3 px-5"
              reloadcard
              @searchResult="handleSearch($event)"
              @reload="getModeration()"
            />
          </template>
        </Card>
      </div>

      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div class="w-full mt-3" v-else>
        <Table
          :headers="moderationHeader"
          :items="moderationStructure"
          v-if="moderationStructure.length > 0 || loadingTables"
          class="py-2 px-3"
          :has-checkbox="false"
          :has-number="true"
          :loading="loadingTables"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
        >
          <template v-slot:item="{ item }">
            <div v-if="item.templateId" class="p-2">
              <span class="text-sm">
                {{ setTemplateName(item.data.templateId) }}
              </span>
            </div>
            <div v-else-if="item.moderators" class="p-2">
              {{ item.data.moderators }}%
            </div>
            <div v-else-if="item.status" class="flex flex-wrap">
              <div class="flex w-auto">
                <div
                  class="mb-2 capitalize"
                  style="min-width: 118px; height: 28px; padding: 5px 0px"
                >
                  <Badge
                    :label="item.data.status"
                    v-if="item.data.status === 'active'"
                    variant="primary"
                    background-color="rgba(19, 181, 106, 0.15)"
                    style="padding: 8px"
                    color="#13B56A"
                  />
                  <Badge
                    :label="item.data.status"
                    v-if="item.data.status === 'inactive'"
                    variant="primary"
                    background-color="rgba(233, 147, 35, 0.15)"
                    style="padding: 8px"
                    color="#E99323"
                  />
                  <Badge
                    :label="item.data.status"
                    v-if="item.data.status === 'draft'"
                    variant="primary"
                    background-color="rgba(234, 60, 83, 0.15)"
                    style="padding: 8px"
                    color="#EA3C53"
                  />
                </div>
              </div>
            </div>
            <div class="flex" v-else-if="item.id">
              <Menu left top="-15" class="p-0" margin="30">
                <template v-slot:title>
                  <icon
                    icon-name="more_icon"
                    size="xsm"
                    class="-mt-1"
                    style="padding: 3px 10px; width: 36px; height: 36px"
                  />
                </template>
                <div style="width: 158px; height: 80px" class="py-3 px-2">
                  <div
                    class="flex py-1 px-3 cursor-pointer"
                    @click="viewModeration(item.data.id)"
                  >
                    <icon
                      icon-name="edit"
                      class-name="text-blueCrayola mr-2"
                      size="xs"
                    />
                    <p class="pt-1" style="font-size: 14px">View and Edit</p>
                  </div>
                  <div
                    class="flex px-3 py-1 more h-8 cursor-pointer"
                    @click="initiateDelete(item.data.id)"
                  >
                    <icon
                      icon-name="delete"
                      class-name="text-desire mr-2"
                      size="xs"
                    />
                    <p class="pt-1" style="font-size: 14px">Delete</p>
                  </div>
                </div>
              </Menu>
            </div>
          </template>
        </Table>

        <div class="flex flex-wrap w-full my-1 p-5 justify-center" v-else>
          <div class="my-4 w-full flex py-6 justify-center">
            <img src="@/assets/images/empty_template.png" alt="" />
          </div>
          <div class="w-3/5 flex justify-center">
            <p class="text-base mt-5 text-center">
              You have not setup your Appraisal & Moderation. Please do so with
              the button below.
            </p>
          </div>

          <div
            class="text-flame font-semibold flex w-full justify-center my-4 p-2 text-base cursor-pointer"
            @click="
              $router.push({
                name: 'ModerationStructure',
                params: { id: 'create' },
              })
            "
          >
            <icon icon-name="icon-plus" class="mr-2" size="xsm" />
            Create New
          </div>
        </div>
      </div>

      <Modal v-if="dialogDel">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <p class="font-bold ml-4 my-5 text-center">
              Are you sure you want to delete this Moderation Structure?
            </p>
            <div class="flex">
              <Button class="bg-dynamicBackBtn text-white" @click="deleteModeration">
                Yes
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="dialogDel = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </div>

    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Icon from "@/components/Icon";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "PerformanceModeration",
  components: {
    Button,
    Modal,
    Card,
    CardFooter,
    Badge,
    Table,
    Menu,
    Icon,
    ErrorPage,
  },
  data() {
    return {
      category: [
        {
          name: "Manager self appraisal",
          radioName: "appraisal",
          value: "managerial_appraisal",
        },
        {
          name: "Employee self appraisal",
          radioName: "appraisal",
          value: "employee_appraisal",
        },
        { name: "Both", radioName: "appraisal", value: "both" },
      ],
      moderationHeader: [
        { title: "Reference Template", value: "templateId" },
        { title: "Appraisees", value: "appraisee" },
        { title: "Moderators", value: "moderators" },
        { title: "Moderation Sequence", value: "sequence" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      moderationStructure: [],
      templateOptions: [],
      dialogDel: false,
      deleteId: "",
      loading: false,
      loadingTables: true,
      errorPage: false,
      searchQuery: '',
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTables = true;
      const search = this.searchQuery ? `&search=${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getModeration(`?${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `?search=${value}`;
      this.loadingTables = true;
      this.getModeration(this.searchQuery);
    },

    async initiateDelete(id) {
      try {
        await this.$handlePrivilege(
          "performance",
          "deletePerformanceModeration"
        );
        this.dialogDel = true;
        this.deleteId = id;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async createModeration() {
      try {
        await this.$handlePrivilege(
          "performance",
          "addNewAppraisal&Moderation"
        );
        this.$router.push({
          name: "ModerationStructure",
          params: { id: "create" },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async viewModeration(id) {
      try {
        await this.$handlePrivilege("performance", "viewAppraisal&Moderation");
        this.$router.push({
          name: "ModerationStructure",
          params: { id },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    setTemplateName(templateId) {
      let name;

      this.templateOptions.map((template) => {
        if (template.id === templateId) {
          name = template.name;
        }
        return {};
      });

      return name || "";
    },

    getModeration(params) {
      this.moderationStructure = [];
      this.loading = true;

      this.$_getModerationStructure(params).then((response) => {
        const moderationData =
          response.data.moderationStructureModeratorsbyOrgd;
        this.metaData = response.data.meta;
        this.loading = false;

        moderationData.forEach((structure) => {
          let totalWeight = 0;

          structure.moderators.forEach((moderatorWeight) => {
            totalWeight += moderatorWeight.weight;
          });

          this.moderationStructure.push({
            id: structure.id,
            templateId: structure.templateId,
            appraisee: structure.employeesIds.length,
            moderators: totalWeight + structure.automaticModeratorWeight,
            sequence: structure.moderators.length + 1,
            status: structure.status,
          });
        });

        this.loadingTables = false;
      });
    },

    deleteModeration() {
      this.$_deleteModerationStructure(this.deleteId)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.dialogDel = false;
          this.getModeration();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    getTemplates() {
      let templatesArr = [];

      this.$_getPerfomanceTemplate('').then((response) => {
        templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          this.templateOptions.push({
            id: template.id,
            name: template.title,
          });
          return {};
        });
      });
    },

    reload() {
      this.loadingTables = true;
      this.getModeration('');
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performance",
        "accessAppraisal&ModerationSettings"
      );
      this.getModeration('');
      this.getTemplates();
    } catch (error) {
      this.errorPage = true;
    }
  },
};
</script>
