<template>
  <div class="w-full">
    <div v-if="!errorPage">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="mt-6 w-full px-3" v-else>
      <div class="flex w-full flex-col">
        <div class="flex flex-col w-full">
          <div class="flex flex-col w-full">
            <div class="flex justify-end w-full mt-4">
              <Button
                width="6rem"
                class="bg-dynamicBackBtn text-white mr-6 text-center"
                @click="
                  setUpPerformanceCycle(cycles.length === 0 ? `Setup` : `View`)
                "
              >
                {{ cycles.length === 0 ? `Setup` : `View` }}
              </Button>
            </div>
          </div>

          <div class="flex flex-col w-full mt-5">
            <h1 class="text-xl text-left font-bold flex">Agreement Cycle</h1>
            <div class="flex w-full mt-4">
              <p class="text-base flex flex-grow mt-5">
                Set up your performance and contracting period for your
                organization.
              </p>
            </div>
          </div>

          <div class="flex flex-col w-full mt-10">
            <h1 class="text-xl text-left font-bold flex">Appraisal Cycle</h1>
            <div class="flex w-full mt-4">
              <p class="text-base flex flex-grow mt-5">
                Set up an appraisal cycle for your organization, either annual,
                bi-annual, quarterly or custom.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import ErrorPage from "@/modules/Admin/error403";
import paramsMixin from "@/utilities/paramsMixin";

export default {
  mixins: [paramsMixin],
  components: {
    Button,
    ErrorPage
  },
  data() {
    return {
      cycles: [],
      errorPage: false
    };
  },

  methods: {
    async setUpPerformanceCycle(action) {
      switch (action) {
        case "Setup":
          try {
            await this.$handlePrivilege("performance", "setupPerformanceCycle");
            this.$router.push({ name: "CreateCycles" });
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        case "View":
          try {
            await this.$handlePrivilege(
              "performance",
              "updatePerformanceCycle"
            );
            this.$router.push({ name: "EditCycles" });
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        default:
          return true;
      }
      return "";
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        this.cycles = response.data.AppraisalCycle;
      });
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performance",
        "accessPerformanceCycleSettings"
      );
      this.getAppraisalCycle();
    } catch (error) {
      this.errorPage = true;
    }
  },
};
</script>
