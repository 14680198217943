<template>
  <div class="py-6 w-full px-3">
    <div v-if="plan === 'basic' || plan === 'standard'">
          <h1 class="text-xl text-left font-bold flex mb-4 -mt-2">Performance</h1>
          <div class="w-full border my-1" />
          <ErrorPageAcess />
    </div>
    <div v-else>
    <h1 class="text-xl text-left font-bold flex mb-4 -mt-2">
      Performance
    </h1>
    <div class="w-full border my-1" />
    <Tabs
      class="mt-3"
      :tabs="tabsItems"
      :active-tab="activeTab"
      @currentTab="activateTab($event)"
      :border="true"
    />
    <AppraisalCycle
    v-if="activeTab === 'Performance Cycle'"
    />
    <AppraisalScores
      v-if="activeTab === 'Ratings & Rankings'"
      @openDescription="showDescription = true"
    />
    <PerformanceTemplate
      v-if="activeTab === 'Performance Templates'"
    />
    <PerformanceModeration
      v-if="activeTab === 'Appraisal & Moderation'"
    />
    <PIPCriteria
      v-if="activeTab === 'PIP Criteria'"
      v-feature-request
    />
    <GeneralSettings
      v-if="activeTab === 'General Settings'"
    />
    </div>
  </div>
</template>

<script>
import Tabs from '@scelloo/cloudenly-ui/src/components/tab';
import ErrorPageAcess from "@/modules/Admin/error404";
import AppraisalScores from './AppraisalScores/index';
import PerformanceTemplate from './Template/PerformanceTemplate';
import GeneralSettings from './GeneralSettings';
import PerformanceModeration from './Moderation/PerformanceModeration';
import PIPCriteria from './PIPCriteria';
import AppraisalCycle from './AppraisalCycle';

export default {
  components: {
    Tabs,
    AppraisalScores,
    AppraisalCycle,
    PerformanceTemplate,
    PerformanceModeration,
    GeneralSettings,
    ErrorPageAcess,
    PIPCriteria
  },
  data() {
    return {
      activeTab: this.$route.query.activeTab || "Performance Cycle",
      kpaModal: false,
      tabsItems: [
        "Performance Cycle",
        "Performance Templates",
        "Ratings & Rankings",
        "Appraisal & Moderation",
        "General Settings",
      ],
    };
  },
  computed: {
    plan() {
      const result = this.$store.state.subscription ? this.$store.state.subscription.plan : null
      return result
    },
  },

  methods: {
    activateTab(tab) {
      this.activeTab = tab;
      this.$router.push({name: 'PerformanceSettings',
        query: { activeTab: tab }
        }).catch(()=>{});
    }
  }
};
</script>

<style>
.border__dashed
{
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878E99;
}
 </style>
