<template>
  <div class="p-5">
    <div
      class="w-full h-auto flex flex-col"
      style="align-items: center"
    >
      <div
        class="w-5/12 h-auto mt-5 mb-5 flex"
        style="justify-content: center"
      >
        <icon
          icon-name="illustration"
          class-name="icon-Size mb-4 mt-4"
        />
      </div>
      <div
        class="w-6/12 h-auto text-center"
        style="width: 610px; line-height:19px"
      >
        <p class="mt-5 mb-5 tracking-wide">
          You have not setup your performance improvement plan criteria.
          You need to specify the conditions
          for profiling an employee in the performance improvement plan
          automatically, the success criteria
          post PIP, how many review sessions within the PIP duration
          and their relative weights, then the
          signatories for each stage of the performance improvement process.
        </p>
        <p class="mt-5">
          <button
            class="flex m-auto"
            @click="$router.push({name:'EditPIPCriteria'})"
          >
            <Icon
              size="xs"
              icon-name="icon-edit"
              class="mt-1"
              style="color: #F15A29;"
            />
            <p class="ml-3 mt-1 font-semibold text-flame">
              Edit PIP Criteria
            </p>
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "EmptyPIPCriteria",
  components: {
    Icon,
  },
};
</script>

<style scoped>
.icon-Size {
  width: 200px;
  height: 200px;
}
</style>
