<template>
  <div class="w-full">
    <div v-if="!errorPage">

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="mt-6 w-full px-3" v-else>
      <div class="flex w-full flex-col">
        <div class="flex flex-col w-full">
          <div class="flex flex-col w-full">
            <h1 class="text-xl text-left font-bold flex">
              Performance Ratings
              <div
                style="position: relative; display: inline-block"
                @mouseover="showRating = true"
                @mouseleave="showRating = false"
              >
                <Icon
                  class="ml-3 mt-1"
                  icon-name="icon-info"
                  size="xsm"
                  style="cursor: pointer"
                />
                <ToolTip v-if="showRating" width="298px" left="-127px">
                  <template v-slot:header>
                    <p class="font-12 uppercase font-semibold">
                      Performance Rating
                    </p>
                  </template>
                  <p style="font-size: 14px">
                    Here you can decide your organization’s rating scale for
                    appraisals. This sets a limit for appraisers, and they
                    cannot give an appraisal score higher or lower than the
                    specified range.
                  </p>
                </ToolTip>
              </div>
            </h1>
            <div class="flex w-full mt-4">
              <p class="text-base flex flex-grow mt-5">
                Set the highest and lowest ratings available in your appraisal
                scorings.
              </p>
              <Button
                width="6rem"
                class="bg-dynamicBackBtn text-white mr-6 text-center"
                @click="handleRatingsClick(Ratings.length === 0 ? `Setup` : `Edit`)"
              >
                {{ Ratings.length === 0 ? `Setup` : `Edit` }}
              </Button>
            </div>
          </div>

          <div
            v-if="Ratings.length > 0"
            class="w-full flex bg-ghostWhite p-3 my-5"
            style="border-radius: 5px"
          >
            <div class="flex flex-col flex-grow justify-start">
              <div
                class="w-full my-3 text-base"
                v-for="(ratings, index) in Ratings.slice(0, 4)"
                :key="index"
              >
                {{ ratings.rating }} = {{ ratings.description }}
              </div>
            </div>

            <div class="flex flex-col justify-start">
              <div
                class="w-full my-3 text-base"
                v-for="(ratings, index) in Ratings.slice(4, 6)"
                :key="index"
              >
                {{ ratings.rating }} = {{ ratings.description }}
              </div>
              <div
                class="w-full my-3 text-sm text-flame uppercase font-semibold cursor-pointer"
                v-if="Ratings.length > 4"
                @click="showDescription = true"
              >
                See All
              </div>
            </div>
          </div>
        </div>

        <div
          class="w-full border border-dashed my-4"
          style="border-color: #878e99; border-width: 0.5px"
        />
      </div>

      <div class="flex flex-wrap mt-4">
        <div class="flex w-full flex-col">
          <h1 class="text-xl text-left font-bold flex">
            Performance Rankings
            <div
              style="position: relative; display: inline-block"
              @mouseover="showRanking = true"
              @mouseleave="showRanking = false"
            >
              <Icon
                class="ml-3 mt-1"
                icon-name="icon-info"
                size="xsm"
                style="cursor: pointer"
              />
              <ToolTip v-if="showRanking" width="298px" left="-127px">
                <template v-slot:header>
                  <p class="font-12 uppercase font-semibold">
                    Performance Ranking
                  </p>
                </template>
                <p style="font-size: 14px">
                  In performance ranking, you are classifying the various rates
                  within bands and defining what your organization describes
                  such performance level.
                </p>
              </ToolTip>
            </div>
          </h1>
          <div class="flex w-full mt-5">
            <p class="w-4/5 flex flex-grow">
              Performance bands are used to categorize a appraisal scores. Each
              band is a range of ratings are labeled to indicate what the score
              ranges mean.
            </p>
            <Button
              width="6rem"
              class="bg-dynamicBackBtn mr-5 text-white"
              @click="
                handleEditRanking(Ranking.length === 0 ? `Setup` : `Edit`)
              "
            >
              {{ Ranking.length === 0 ? `Setup` : `Edit` }}
            </Button>
          </div>

          <div class="flex w-full flex-col pr-4">
            <Table
              :headers="AppraisalHeader"
              :items="Ranking"
              class="w-full mt-7"
              :has-checkbox="false"
              :has-number="false"
              :loading="false"
              v-if="Ranking.length > 0"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.id">
                  <p class="py-3" v-if="item.index === 0">
                    From
                    <span class="font-bold">
                      {{ item.data.from }}
                    </span>
                    to
                    <span class="font-bold">
                      {{ item.data.to }}
                    </span>
                  </p>
                  <p class="py-3" v-else>
                    Above
                    <span class="font-bold">
                      {{ item.data.from }}
                    </span>
                    to
                    <span class="font-bold">
                      {{ item.data.to }}
                    </span>
                  </p>
                </div>
                <div v-else-if="item.description">
                  <p class="py-3">
                    {{ item.data.description.description }}
                  </p>
                </div>
                <div v-else-if="item.flag">
                  <p class="py-3">
                    {{ item.data.flag }}
                  </p>
                </div>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </div>

    <transition name="slider">
      <SideModal v-if="showDescription" top="15%" left="76%" width="328px">
        <template v-slot:title>
          <div class="text-darkPurple -mt-2 w-11/12">Rating Descriptions</div>
          <div @click="showDescription = false">
            <Icon
              icon-name="close"
              size="s"
              style="cursor: pointer; margin: 0px 0px 0px 10px"
            />
          </div>
        </template>
        <div class="flex flex-col mt-6">
          <div
            class="w-full h-5 text-base text-jet mt-2 mb-2"
            v-for="(ratings, index) in Ratings"
            :key="index"
          >
            {{ ratings.rating }} = {{ ratings.description }}
          </div>
        </div>
      </SideModal>
    </transition>

    <ValidationObserver v-slot="{ handleSubmit }">
      <RightSideBar
        v-if="showModal"
        submit="Add"
        @submit="handleSubmit(submit)"
        @close="closeSideBar()"
        button-class="bg-dynamicBackBtn text-white"
      >
        <template v-slot:title>
          <p class="text-darkPurple">Add Rating and Description</p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple" style="margin-bottom: 12px">
            Set the lowest and highest ratings as seen during appraisal ratings.
          </p>
        </template>
        <div
          class="w-full flex my-4 pb-4"
          style="border-bottom: 1px dashed #878e99"
        >
          <div class="w-2/5 flex flex-grow mr-8 h-full">
            <c-text
              class="w-full"
              placeholder="Lowest Rating"
              type="number"
              label="Lowest Rating"
              v-model="lowestValue"
            />
          </div>
          <div class="w-2/5 flex flex-grow h-full">
            <c-text
              class="w-full"
              placeholder="Highest Rating"
              type="number"
              label="Highest Rating"
              v-model="highestValue"
            />
          </div>
        </div>

        <div class="my-4 w-full">
          <p class="font-semibold text-darkPurple text-base">
            Rating Descriptions
          </p>
        </div>
        <div class="w-full h-full">
          <div class="p-2 bg-ghostWhite w-full flex" style="border-radius: 5px">
            <div class="w-2/12 uppercase font-black text-xs text-romanSilver">
              Rating
            </div>
            <div
              class="w-10/12 -ml-3 uppercase font-black text-xs text-romanSilver"
            >
              Description
            </div>
          </div>
          <div
            class="w-full flex items-end my-3 mx-2"
            v-for="ratings in ratingPayload"
            :key="ratings.rating"
          >
            <div style="width: 10%">
              <c-text
                class="w-full -mt-2 "
                disabled
                v-model="ratings.rating"
              />
            </div>
            <div class="w-10/12 ml-6 label">
              <c-text
                class="w-full"
                v-model="ratings.description"
                placeholder="Rating Description"
                :rules="['required']"
                :label="`Description for rating ${ratings.rating}`"
              />
            </div>
          </div>
        </div>
      </RightSideBar>
    </ValidationObserver>
    </div>

    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import ToolTip from "@/components/ToolTip";
import RightSideBar from "@/components/RightSideBar";
import SideModal from "@/components/SideModal";
import Icon from "@/components/Icon";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    Button,
    ValidationObserver,
    Table,
    ToolTip,
    ErrorPage,
    SideModal,
    Icon,
    RightSideBar,
    CText,
  },
  name: "AppraisalScores",
  data() {
    return {
      lowestValue: "",
      highestValue: "",
      lowest_value: null,
      highest_value: null,
      showMore: false,
      showDescription: false,
      Ranking: [],
      Ratings: [],
      showRating: false,
      showRanking: false,
      showModal: false,
      appraisalModal: false,
      errorPage: false,
      loading: true,
      AppraisalHeader: [
        { title: "PERFORMANCE BAND", value: "id" },
        { title: "RANKING DESCRIPTION", value: "description" },
        { title: "APPRAISAL", value: "flag" },
      ],
      ratingPayload: [],
      ratingDescriptions: [],
      descriptions: [],
      appraisalName: "",
    };
  },

  watch: {
    lowestValue(value) {
      const ratings = [];
      const descriptions = this.descriptions.toString().split(",");

      if (value === "") {
        this.ratingPayload = [];
      } else if (value >= 0) {
        for (let i = value; i <= this.highestValue; i++) {
          ratings.push({
            orgId: this.$orgId,
            rating: i,
            description: descriptions[i - value],
          });
        }
        this.ratingPayload = ratings;
      }
    },

    highestValue(value) {
      const ratings = [];
      const descriptions = this.descriptions.toString().split(",");

      if (value === "") {
        this.ratingPayload = [];
      } else if (value >= 0) {
        for (let i = this.lowestValue; i <= Number(value); i++) {
          ratings.push({
            orgId: this.$orgId,
            rating: i,
            description: descriptions[i - this.lowestValue],
          });
        }
        this.ratingPayload = ratings;
      }
    },
  },

  methods: {
    async handleEditRanking(action) {
      switch (action) {
        case "Setup":
          try {
            await this.$handlePrivilege(
              "performance",
              "setupPerformanceRanking"
            );
            this.$router.push({ name: "EditRankings" });
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        case "Edit":
          try {
            await this.$handlePrivilege(
              "performance",
              "editPerformanceRanking"
            );
            this.$router.push({ name: "EditRankings" });
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        default:
          return true;
      }
      return "";
    },

    async handleRatingsClick(action) {
      switch (action) {
        case "Setup":
          try {
            await this.$handlePrivilege(
              "performance",
              "setupPerformanceRatings"
            );
            this.showModal = true;
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        case "Edit":
          try {
            await this.$handlePrivilege(
              "performance",
              "editPerformanceRatings"
            );
            this.showModal = true;
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        default:
          return true;
      }
      return "";
    },

    submit() {
      this.$_createPerfomanceRating(this.ratingPayload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.showModal = false;
          this.getPerfomanceRating();
        })
        .catch((err) => {
          this.$toasted.error("Error in creating Performance rating", {
            duration: 5000,
          });
          throw new Error(err);
        });
    },

    closeSideBar() {
      this.showModal = false;
      this.ratingPayload = this.Ratings;
      this.lowestValue = this.lowest_value;
      this.highestValue = this.highest_value;
      if (this.Ratings.length === 0) {
        this.ratingPayload = [];
      } else {
        this.ratingPayload = this.Ratings;
      }
    },

    descModal() {
      this.$emit("openDescription");
    },

    normalizeRatings() {
      this.descriptions = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;

      this.lowest_value = Math.min.apply(
        this.Ratings,
        this.Ratings.map((item) => item.rating)
      );
      this.highest_value = Math.max.apply(
        this.Ratings,
        this.Ratings.map((item) => item.rating)
      );
      this.lowestValue = this.lowest_value;
      this.highestValue = this.highest_value;

      this.Ratings.map((ratings) => {
        this.descriptions.push(ratings.description);
        return {};
      });
    },

    normalizeRankings(rankings) {
      this.Ranking = [];
      rankings.filter((ranking) => {
        this.Ranking.push({
          id: ranking.id,
          from: ranking.from,
          to: ranking.to,
          description: ranking.description,
          flag: ranking.flag.name,
        });
        return {};
      });
    },

    async getPerfomanceRating() {
      try {
        const getPerfomanceRating = await this.$_getPerfomanceRating();
        const getRanking = await this.$_getRanking();

        let rankings = [];

        rankings = getRanking.data.rankings;
        this.Ratings = getPerfomanceRating.data.PerformanceRatings;

        if (this.Ratings.length === 0) {
          const ratingsArr = [];
          this.ratingPayload = ratingsArr;
        } else {
          this.normalizeRatings();
        }

        this.normalizeRankings(rankings);

        this.loading = false;
      } catch (err) {
        throw new Error(err);
      }
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performance",
        "accessPerformanceRatingsRankingsSettings"
      );
      this.getPerfomanceRating();
    } catch (error) {
      this.errorPage = true;
    }
  },

};
</script>

<style>
.label label {
  display: block !important;
}
</style>

<style scoped>
.dashed_border {
  position: static;
  width: 97.5%;
  height: 0px;
  left: 18px;
  top: 210px;
  border: 1px dashed #878e99;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 18px 0px;
}
.last_description_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #f15a29;
  margin: 5.5px 0px;
  cursor: pointer;
}
.description_text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #333333;
  margin: 5.5px 0px;
}
.button {
  width: 7.875rem;
  height: 2.75rem;
  padding: 12px, 15px, 12px, 15px;
}
.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 100%;
  height: 136px;
  left: 0px;
  top: 38px;
  background: #f7f7ff;
  border-radius: 5px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px;
}
.half {
  flex-basis: 50%;
}
.border-r-2 {
  border-right: 1px solid #878e99;
}
.text-rating {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #333333;
}
.lowest {
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #ea3c53;
}
.highest {
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #13b56a;
}
/* .rantings_height{
  height: 38px;
} */
.rankingtable {
  height: 397px;
  margin: 22px 0px 0px 0px;
}
.editRating__block {
  width: 480px;
  margin: 18px 0px;
}
.ratingInput {
  width: 231px;
  margin: 0px 18px;
}
.ratingInput__label {
  height: 18px;
  font-size: 14px;
  color: #333333;
  line-height: 130%;
  letter-spacing: 0.5px;
  margin: 12px 0px;
}
.editRating__subheader {
  height: 19px;
  margin: 18px 0px;
}
.ratingstable {
  width: 480px;
  margin: 18px 0px;
}
.ratingstable__header {
  height: 33px;
  border-radius: 5px;
  padding: 8px;
  margin: 0px 0px 4px 0px;
  background-color: #f7f7ff;
  color: #878e99;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.035em;
  text-transform: uppercase;
}
.column__xs {
  width: 48px;
  background-color: #eeeeee;
}
.column__lg {
  width: 395px;
}
</style>
