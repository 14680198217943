<template>
  <div class="flex w-full flex-col">
    <div v-if="!errorPage">
      <div class="flex flex-col h-auto">
        <Card class="p-5 w-full">
          <div class="flex mt-6" style="justify-content: flex-end">
            <Button
              class="bg-dynamicBackBtn text-white"
              @click="handleSetUp()"
            >
              Setup PIP Criteria
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="outcomeModal = true"
            >
              PIP Outcome Actions
            </Button>
          </div>
        </Card>
      </div>

      <div style="height: 100%" class="mt-5" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <div class="px-2 mt-3" v-if="pipCriteria.length > 0">
          <Table
            :headers="pipHeaders"
            :items="pipCriteria"
            class="py-2"
            :has-checkbox="false"
            :has-number="true"
            :loading="false"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.templateId" class="p-2">
                <div class="flex py-1text-base">
                  {{ getTemplateName(item.data.templateId) }}
                </div>
              </div>

              <div v-else-if="item.score_slug">
                <span v-if="item.data.score_slug === 'less_than'">
                  &lt; {{ item.data.employeeScore }}
                </span>
                <span v-else> &lt;&equals; {{ item.data.employeeScore }} </span>
              </div>

              <div v-else-if="item.pipOutcome" class="p-2">
                <span class="text-sm"> {{ item.data.pipOutcome }}% </span>
              </div>

              <div v-else-if="item.PerformanceReviewSessions" class="p-2">
                <span class="text-sm">
                  {{ item.data.PerformanceReviewSessions.length }}
                </span>
              </div>

              <div v-else-if="item.performance_outcome" class="p-2">
                <span class="text-sm">
                  {{ item.data.performance_outcome.type }}
                </span>
              </div>

              <div v-else-if="item.pipSignatories">
                <Menu right top="-400" class="p-0" margin="23">
                  <template v-slot:title>
                    <Badge
                      :label="
                        (
                          item.data.pipSignatories.length +
                          item.data.closeoutSessionignatories.length +
                          item.data.pipReviewSignatories.length
                        ).toString()
                      "
                      variant="primary"
                      background-color="rgba(73, 1, 102, 0.15)"
                      style="cursor: pointer; padding: 8px"
                      color="#490166"
                    />
                  </template>
                  <div style="width: 250px; min-height: 80px" class="p-4">
                    <div class="mb-2 font-bold text-base">PIP Plan</div>
                    <div
                      class="border border-dashed mb-4"
                      style="border-width: 0.5px"
                    />
                    <div
                      class="flex mb-2 font-semibold text-base"
                      v-for="(pipPlan, index) in item.data.pipSignatories"
                      :key="index"
                    >
                      {{ getPlanName(pipPlan) }}
                    </div>

                    <div class="mb-2 mt-4 font-bold text-base">PIP Review</div>
                    <div
                      class="border border-dashed mb-2"
                      style="border-width: 0.5px"
                    />
                    <div
                      class="flex mb-2 font-semibold text-base"
                      v-for="(review, index) in item.data.pipReviewSignatories"
                      :key="index"
                    >
                      {{ getReviewName(review) }}
                    </div>

                    <div class="mb-2 mt-4 font-bold text-base">
                      PIP Closeout
                    </div>
                    <div
                      class="border border-dashed mb-4"
                      style="border-width: 0.5px"
                    />
                    <div
                      class="flex py-1 font-semibold text-base"
                      v-for="(closeout, index) in item.data
                        .closeoutSessionignatories"
                      :key="index"
                    >
                      {{ getCloseoutName(closeout) }}
                    </div>
                  </div>
                </Menu>
              </div>

              <div v-if="item.status" class="flex flex-wrap">
                <div class="flex w-auto">
                  <div class="mb-2 capitalize" style="min-width: 118px">
                    <Badge
                      :label="item.data.status"
                      v-if="item.data.status === 'active'"
                      variant="primary"
                      style="padding: 8px"
                      background-color="rgba(19, 181, 106, 0.15)"
                      color="#13B56A"
                    />
                    <Badge
                      :label="item.data.status"
                      v-if="item.data.status === 'draft'"
                      variant="primary"
                      style="padding: 8px"
                      background-color="rgba(234, 60, 83, 0.15)"
                      color="#EA3C53"
                    />
                  </div>
                </div>
              </div>

              <div class="flex" v-else-if="item.id">
                <Menu left top="-30" class="p-0" margin="30">
                  <template v-slot:title>
                    <icon
                      icon-name="more_icon"
                      size="xsm"
                      class="-mt-1"
                      style="padding: 3px 10px; width: 36px; height: 36px"
                    />
                  </template>
                  <div style="width: 158px; height: 80px" class="py-3 px-2">
                    <div
                      class="flex py-1 px-3 cursor-pointer"
                      @click="
                      handleEditPIP(item)
                      "
                    >
                      <icon
                        icon-name="edit"
                        class-name="text-blueCrayola mr-2"
                        size="xs"
                      />
                      <p class="pt-1" style="font-size: 14px">View and Edit</p>
                    </div>
                    <div
                      class="flex px-3 py-1 more h-8 cursor-pointer"
                      @click="initiateDelete(item.data.id)"
                    >
                      <icon
                        icon-name="delete"
                        class-name="text-desire mr-2"
                        size="xs"
                      />
                      <p class="pt-1" style="font-size: 14px">Delete</p>
                    </div>
                  </div>
                </Menu>
              </div>
            </template>
          </Table>
        </div>

        <EmptyPIPCriteria v-else />
      </div>

      <ValidationObserver v-slot="{ handleSubmit }">
        <RightSideBar
          v-if="outcomeModal"
          @close="closeOutcomeModal"
          close-button
          button-class="bg-dynamicBackBtn text-white"
        >
          <template v-slot:title>
            <p class="text-darkPurple">PIP Outcome Actions</p>
          </template>
          <template v-slot:subtitle>
            <p class="font-semibold text-darkPurple my-4 text-base">
              Specify possible actions for unsuccesful PIP outcome.
            </p>
          </template>
          <div class="flex flex-col font-semibold my-4 text-base">Actions</div>
          <div
            class="flex w-full my-4 label"
            v-for="(p, index) in pipActions"
            :key="index"
          >
            <c-text
              v-model="p.type"
              placeholder="Enter Action"
              class="w-11/12"
              :rules="['required']"
              label="Action"
              :disabled="p.saved"
            />
            <div class="flex w-1/12 h-10">
              <div
                v-if="p.saved"
                class="cursor-pointer flex"
                @click="openEdit(p)"
              >
                <icon
                  icon-name="icon-edit"
                  size="xms"
                  class="mt-1 mx-4 text-blueCrayola"
                />
              </div>
              <div
                class="flex cursor-pointer"
                v-else
                @click="
                  p.edit ? handleSubmit(editPIP(p)) : handleSubmit(savePIP(p))
                "
              >
                <icon
                  icon-name="save"
                  class="mt-1 mx-4 text-blueCrayola"
                  size="xms"
                  style="width: 0.8rem"
                />
              </div>
              <div class="flex cursor-pointer" @click="deletePIP(p, index)">
                <icon icon-name="icon-trash" class="mt-1" size="xs" />
              </div>
            </div>
          </div>
          <div
            class="text-flame font-semibold flex w-full my-4 p-2 text-base cursor-pointer"
            @click="createPIPOutcome"
          >
            <icon icon-name="icon-plus" class="mr-2" size="xsm" />
            Add Action
          </div>
        </RightSideBar>
      </ValidationObserver>

      <Modal v-if="dialogDel">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <p class="font-bold ml-4 my-5 text-center">
              Are you sure you want to delete this PIP Criteria?
            </p>
            <div class="flex">
              <Button class="bg-dynamicBackBtn text-white" @click="deletePIPSettings">
                Yes
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="dialogDel = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </div>

    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import Card from "@/components/Card";
import RightSideBar from "@/components/RightSideBar";
import Modal from "@/components/Modal";
import ErrorPage from "@/modules/Admin/error403";
import EmptyPIPCriteria from "./EmptyPIPCriteria";

export default {
  name: "PIPCriteria",
  components: {
    ValidationObserver,
    CText,
    Table,
    ErrorPage,
    Button,
    Badge,
    Menu,
    Modal,
    Card,
    EmptyPIPCriteria,
    RightSideBar,
  },

  data() {
    return {
      pipCriteria: [],
      loading: true,
      dialogDel: false,
      outcomeModal: false,
      pipActions: [],
      templates: [],
      designations: [],
      pipId: "",
      errorPage: false,
      pipHeaders: [
        { title: "Reference Template", value: "templateId" },
        { title: "Pip Criteria", value: "score_slug" },
        { title: "Success criteria", value: "pipOutcome" },
        { title: "Review Sessions", value: "PerformanceReviewSessions" },
        { title: "Unsuccessful Action", value: "performance_outcome" },
        { title: "Signatories", value: "pipSignatories" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
    };
  },

  methods: {
    async handleSetUp() {
      try {
        await this.$handlePrivilege(
          "Setup PIP Criteria",
          "setupPipCriteria"
        );
        this.$router.push({ name: 'EditPIPCriteria' })
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async handleEditPIP(item) {
      try {
        await this.$handlePrivilege(
          "Edit PIP Criteria",
          "editPipCriteria"
        );
        this.$router.push({
          name: 'EditPIPCriteria',
          params: { id: item.data },
        })
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    getReviewName(pipReviewSignatories) {
      let name = "";
      this.designations.filter((designation) => {
        if (designation.id === pipReviewSignatories) {
          name = designation.name;
        }
        return {};
      });
      return name;
    },

    getPlanName(pipSignatories) {
      let name = "";
      this.designations.filter((designation) => {
        if (designation.id === pipSignatories) {
          name = designation.name;
        }
        return {};
      });
      return name;
    },

    getCloseoutName(closeoutSessionSignatories) {
      let name = "";
      this.designations.filter((designation) => {
        if (designation.id === closeoutSessionSignatories) {
          name = designation.name;
        }
        return {};
      });
      return name;
    },

    getTemplateName(id) {
      let name = "";
      this.templates.filter((template) => {
        if (template.id === id) {
          name = template.title;
        }
        return {};
      });
      return name;
    },

    savePIP(pipAction) {
      let payload = {};
      const indexedAction = pipAction;

      payload = {
        orgId: pipAction.orgId,
        type: pipAction.type,
      };

      indexedAction.saved = true;

      this.$_createPIPOutcome(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getPIPOutcome();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    editPIP(pipAction) {
      let payload = {};
      const indexedAction = pipAction;

      payload = {
        orgId: pipAction.orgId,
        type: pipAction.type,
        id: pipAction.id,
      };

      indexedAction.saved = true;

      this.$_editPIPOutcome(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    deletePIP(pipAction, index) {
      if (pipAction.id) {
        this.$_deletePIPOutcome(pipAction.id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.pipActions.splice(index, 1);
        });
      } else {
        this.pipActions.splice(index, 1);
      }
    },

    closeOutcomeModal() {
      this.outcomeModal = false;
      this.getPIPOutcome();
    },

    openEdit(pipAction) {
      const indexedAction = pipAction;
      indexedAction.saved = false;
      indexedAction.edit = true;
    },

    normalizePIPActions(pipOutcome) {
      this.pipActions = [];

      pipOutcome.filter((outcome) => {
        this.pipActions.push({
          id: outcome.id,
          orgId: outcome.orgId,
          type: outcome.type,
          saved: true,
          edit: true,
        });
        return {};
      });

      if (pipOutcome.length === 0) {
        this.pipActions.push({
          orgId: this.$orgId,
          type: "",
          saved: false,
          edit: false,
        });
      }
    },

    createPIPOutcome() {
      this.pipActions.push({
        orgId: this.$orgId,
        type: "",
        saved: false,
        edit: false,
      });
    },

    getPIPOutcome() {
      this.$_getPIPOutcome().then((response) => {
        const pipOutcome = response.data.outcome;
        this.normalizePIPActions(pipOutcome);
      });
    },

    getPIPSettings() {
      this.loading = true;
      this.$_getPIPSetting().then((response) => {
        this.pipCriteria = response.data.plans;
        this.loading = false;
      });
    },

    initiateDelete(id) {
      this.dialogDel = true;
      this.pipId = id;
    },

    deletePIPSettings() {
      this.$_deletePIPSetting(this.pipId)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.dialogDel = false;
          this.getPIPSettings();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.forEach((designation) => {
          this.designations.push({
            id: designation.id,
            name: designation.name,
          });
          return {};
        });
      });
    },

    getTemplates() {
      let templatesArr = [];
      this.$_getPerfomanceTemplate('').then((response) => {
        templatesArr = response.data.PerformanceTemplate;
        this.templates = templatesArr;
      });
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performance",
        "accessPipCriteriaSettings"
      );
      this.getPIPOutcome();
      this.getPIPSettings();
      this.getTemplates();
      this.getDesignations();
    } catch (error) {
      this.errorPage = true;
    }
  },
};
</script>
