<template>
  <div class="w-full">
    <div v-if="!errorPage">
    <div class="px-3">
      <h1 class="text-flame font-extrabold mt-3 uppercase">
        Performance Agreements
      </h1>

      <div class="mt-3">
        <div class="mt-5">
          <div class="flex w-full items-center">
            <radio-button
              :options="document_options"
              :col-span="`col-span-4`"
              class="text-darkPurple"
              row-gap="gap-y-2 gap-x-10"
              space-between="mr-3"
              v-model="settingsPayload.editDocument"
              @change="agreementEdit($event)"
            />
          </div>
          <div class="flex w-full mt-7 mb-5 items-center">
            <checkbox
              label="Manager can edit employee agreement"
              class="w-2/5"
              value="1"
              label-style="color:#333333"
              checkbox-size="height:16px; width:16px; margin-top: 4px"
              v-model="settingsPayload.managerCanEditAgreement"
            />
            <checkbox
              label="Manager can lock agreement for editing"
              label-style="color:#333333"
              checkbox-size="height:16px; width:16px; margin-top: 4px;"
              v-model="settingsPayload.managerCanLockAgreement"
            />
          </div>
          <div>
            <checkbox
              label="Submitted agreement is locked for editing for both manager and employee"
              label-style="color:#333333"
              checkbox-size="height:16px; width:16px; margin-top: 4px"
              v-model="settingsPayload.lockSubmittedAgreement"
            />
          </div>
        </div>

        <div class="mt-6">
          <h4 class="text-darkPurple text-base font-bold px-2">
            Automatically send reminders to employees who have not submitted
            performance agreements.
          </h4>

          <div class="mt-5">
            <div class="flex w-full">
              <div class="flex flex-col">
                <checkbox
                  label="Yes, send reminders"
                  class="w-60"
                  value="1"
                  label-style="color:#333333"
                  checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
                  v-model="settingsPayload.sendReminders"
                />
                <div
                  class="flex ml-4"
                  style="border-left: 1px dashed #878e99"
                  v-if="settingsPayload.sendReminders"
                >
                  <c-select
                    placeholder="Select"
                    variant="w-full capitalize"
                    class="w-40 ml-4"
                    :options="reminderOptions"
                    v-model="selectReminder"
                  />
                </div>
              </div>
              <div class="-ml-4" v-if="settingsPayload.sendReminders">
                <CTextarea
                  placeholder="--Custom Message--"
                  variant="primary w-1/2"
                  style="width: 100%"
                  value=""
                  :row="8"
                  :height="90"
                  :input="null"
                  v-model="settingsPayload.reminderMessage"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="w-full flex items-center py-5">
          <div class="border border-romanSilver w-full h-0 mr-4 mt-5" />
        </div>

        <!---- <h1 class="text-flame font-extrabold mt-3 uppercase">
        Performance Appraisals
      </h1>

      <div class="mt-3">
        <h4 class="text-darkPurple text-lg font-bold">
          Who are involved in the appraisal process?
        </h4>

        <div class="mt-5">
          <div class="flex w-full items-center">
            <checkbox
              label="Employees"
              class="w-80"
              value="1"
              label-style="color:#333333"
              checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
              v-model="settingsPayload.employeeInAppraisalProcess"
            />
            <checkbox
              label="Direct Line Managers"
              class="w-80"
              value="1"
              label-style="color:#333333"
              checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
              v-model="settingsPayload.lineManagerInAppraisalProcess"
            />
            <checkbox
              label="Dotted line managers"
              value="2"
              label-style="color:#333333"
              checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
              v-model="settingsPayload.dottedLineManagerInAppraisalProcess"
            />
          </div>

          <checkbox
            label="Allow assigning alternate appraisers"
            class="mt-3"
            value="3"
            label-style="color:#333333"
            checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
            v-model="settingsPayload.othersInAppraisalProcess"
          />

          <p class="uppercase mt-8 text-sm">
            Note: Only employee’s direct line manager can approve appraisal scores.
            The approved appraisal score becomes the average of both the direct line manageR,
            level 2 manager and any other appraiser assigned to them
          </p>
        </div>
      </div>
      <div class="w-full flex items-center py-5">
        <div class="border border-romanSilver w-full h-0 mr-4 mt-5" style="border-width : 0.5px" />
      </div>-->

        <h1 class="text-flame font-extrabold mt-3 uppercase">
          Appraisal Scoring
        </h1>

        <div class="my-2 text-base font-semibold">
          Choose your appraisal scoring preference.
        </div>

        <div class="mt-3">
          <div class="mt-5">
            <div class="flex w-full items-center">
              <radio-button
                :options="appraisal_options"
                :col-span="`col-span-4`"
                class="text-darkPurple"
                row-gap="gap-y-2 gap-x-10"
                space-between="mr-3"
                v-model="settingsPayload.appraisailScoringPreference"
                @change="scoringPrefrence($event)"
              />
            </div>
            <div class="flex w-full mt-7 mb-5 items-center">
              <checkbox
                label="Manager can edit employee self-appraisal"
                class="w-2/5"
                value="1"
                label-style="color:#333333"
                checkbox-size="height:16px; width:16px; margin-top: 4px"
                v-model="settingsPayload.managerCanEditEmployeeAppraisal"
                :disabled="settingsPayload.appraisailScoringPreference === 'manager_to_appraisee'"
              />
              <checkbox
                label="Manager can lock appraisal for editing"
                label-style="color:#333333"
                checkbox-size="height:16px; width:16px; margin-top: 4px;"
                v-model="settingsPayload.managerCanLockAppraisalForEditing"
              />
            </div>
            <div>
              <checkbox
                label="Submitted appraisal is locked for editing for both manager and employee"
                label-style="color:#333333"
                checkbox-size="height:16px; width:16px; margin-top: 4px"
                v-model="settingsPayload.lockSubmittedAppraisal"
              />
            </div>
          </div>
        </div>
        <div class="w-full flex items-center py-5" v-feature-request>
          <div
            class="border border-romanSilver w-full h-0 mr-4 mt-5"
            style="border-width: 0.5px"
          />
        </div>

        <h1 class="text-flame font-extrabold mt-3 uppercase" v-feature-request>
          Performance Moderation
        </h1>
        <div class="mt-5" v-feature-request>
          <h4 class="text-darkPurple text-lg font-bold">
            Allow moderators see each others scores?
          </h4>

          <div class="mt-5">
            <div class="flex w-full items-center">
              <radio-button
                :options="moderators_options"
                :col-span="`col-span-6`"
                class="text-darkPurple"
                row-gap="gap-y-4 gap-x-4"
                space-between="mr-2"
                v-model="settingsPayload.allowModeratorsSeeScore"
                @change="moderatorScore($event)"
              />
            </div>
          </div>
        </div>

        <div class="w-full flex items-center py-5" v-feature-request>
          <div
            class="border border-romanSilver w-full h-0 mr-4 mt-5"
            style="border-width: 0.5px"
          />
        </div>

        <h1 class="text-flame font-extrabold mt-3 uppercase" v-feature-request>
          Performance Improvement
        </h1>
        <div class="mt-5" v-feature-request>
          <h4 class="text-darkPurple text-lg font-bold">
            Who can create performance improvement plans for underperforming
            employees?
          </h4>

          <div class="mt-5">
            <div class="flex w-full items-center">
              <radio-button
                :options="improvementPlan_creators"
                :col-span="`col-span-6`"
                class="text-darkPurple"
                row-gap="gap-y-4 gap-x-4"
                space-between="mr-2"
                v-model="settingsPayload.permittedToEditPIP"
              />
            </div>
          </div>
        </div>

        <div class="w-full flex items-center py-5">
          <div
            class="border border-romanSilver w-full h-0 mr-4 mt-5"
            style="border-width: 0.5px"
          />
        </div>

        <div class="flex mt-5">
          <div class="flex flex-grow">
            <Button
              class="bg-dynamicBackBtn text-white text-center"
              width="8rem"
              @click="submitHandler"
              :disabled="disableBtn"
            >
              Save Changes
            </Button>
            <Button
              background-color="none"
              class="ml-4 text-darkPurple text-center"
              width="8rem"
              @click="getGeneralSetting"
            >
              Revert Changes
            </Button>
          </div>
        </div>
      </div>
    </div>

    </div>

    <div v-else>
      <ErrorPage />
    </div>

  </div>
</template>

<script>
import Checkbox from "@scelloo/cloudenly-ui/src/components/checkbox";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import RadioButton from "@/components/RadioButton";
import Button from "@/components/Button";
import ErrorPage from "@/modules/Admin/error403";
// import Button from '@/components/Button';
// import ToolTip from '@/components/ToolTip';

export default {
  components: {
    Checkbox,
    RadioButton,
    CSelect,
    CTextarea,
    Button,
    ErrorPage,
  },
  name: "AppraisalScores",
  data() {
    return {
      showRating: false,
      showRanking: false,
      disableBtn: true,
      AppraisalRating: [
        {
          band: "From 0 to 2",
          description: "Very Poor",
          flag: "Slipping Target",
        },
        { band: "Above 2 to 3", description: "Poor", flag: "Slipping Target" },
        {
          band: "Above 3 to 5",
          description: "Below Average",
          flag: "Slipping Target",
        },
        { band: "Above 5 to 6", description: "Average", flag: "On Target" },
        { band: "Above 6 to 8", description: "Good", flag: "On Target" },
        {
          band: "Above 8 to 9",
          description: "Very Good",
          flag: "Above Target",
        },
        {
          band: "Above 9 to 10",
          description: "Beyond Exceptional",
          flag: "Beyond Achievable Target",
        },
      ],
      document_options: [
        {
          name: "Manager to document",
          radioName: "document",
          value: "manager",
        },
        {
          name: "Employee to document",
          radioName: "document",
          value: "employee",
        },
        { name: "Both", radioName: "document", value: "both" },
      ],
      appraisal_options: [
        {
          name: "Manager to appraise",
          radioName: "appraisal",
          value: "manager_to_appraisee",
        },
        {
          name: "Employee self-appraise",
          radioName: "appraisal",
          value: "employee_self_appraisal",
        },
        { name: "Both", radioName: "appraisal", value: "both" },
      ],
      moderators_options: [
        { name: "Yes", radioName: "moderators", value: true },
        { name: "No", radioName: "moderators", value: false },
      ],
      improvementPlan_creators: [
        {
          name: "Admin",
          radioName: "improvementPlan",
          subtitle: "*Subject to Roles and Priviledges.",
          value: "admin",
        },
        {
          name: "Direct Line Managers",
          radioName: "improvementPlan",
          value: "direct_line_manager",
        },
      ],
      reminderOptions: [
        {
          id: 1,
          name: "weekly",
        },
        {
          id: 2,
          name: "monthly",
        },
      ],
      selectReminder: null,
      settingsPayload: {
        orgId: null,
        editDocument: "",
        managerCanEditAgreement: false,
        managerCanLockAgreement: false,
        lockSubmittedAgreement: false,
        sendReminders: false,
        frequency: "",
        reminderMessage: "",
        employeeInAppraisalProcess: false,
        lineManagerInAppraisalProcess: false,
        dottedLineManagerInAppraisalProcess: false,
        othersInAppraisalProcess: false,
        appraisailScoringPreference: "",
        managerCanEditEmployeeAppraisal: false,
        managerCanLockAppraisalForEditing: false,
        lockSubmittedAppraisal: false,
        allowModeratorsSeeScore: false,
        permittedToEditPIP: "admin",
      },
      errorPage: false,
    };
  },
  watch: {
    selectReminder(value) {
      this.reminderOptions.filter((option) => {
        if (option.id === Number(value)) {
          this.settingsPayload.frequency = option.name;
        }
        return {};
      });
    },
  },

  methods: {
    agreementEdit(val) {
      if (val) {
        this.disableBtn = false;
      }
    },

    scoringPrefrence(val) {
      if (val) {
        this.disableBtn = false;
      }
    },

    moderatorScore(val) {
      if (val) {
        this.disableBtn = false;
      }
    },

    async submitHandler() {
      try {
        await this.$handlePrivilege(
          "performance",
          "updatePerformanceGeneralSetting"
        );
        this.handleSubmit();
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    handleSubmit() {
      this.settingsPayload.orgId = this.$orgId;

      this.$_createPerfomanceSetting(this.settingsPayload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    getGeneralSetting() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        response.data.PerformanceSettings.map((setting) => {
          this.settingsPayload.settingId = setting.id;
          this.settingsPayload.orgId = setting.orgId;
          this.settingsPayload.editDocument = setting.editDocument;
          this.settingsPayload.dottedLineManagerInAppraisalProcess =
            setting.dottedLineManagerInAppraisalProcess;
          this.settingsPayload.othersInAppraisalProcess =
            setting.othersInAppraisalProcess;
          this.settingsPayload.appraisailScoringPreference =
            setting.appraisailScoringPreference;
          this.settingsPayload.managerCanEditEmployeeAppraisal =
            setting.managerCanEditEmployeeAppraisal;
          this.settingsPayload.managerCanLockAppraisalForEditing =
            setting.managerCanLockAppraisalForEditing;
          this.settingsPayload.lockSubmittedAppraisal =
            setting.lockSubmittedAppraisal;
          this.settingsPayload.allowModeratorsSeeScore =
            setting.allowModeratorsSeeScore;
          this.settingsPayload.permittedToEditPIP = setting.permittedToEditPIP;
          this.settingsPayload.managerCanEditAgreement =
            setting.managerCanEditAgreement;
          this.settingsPayload.managerCanLockAgreement =
            setting.managerCanLockAgreement;
          this.settingsPayload.lockSubmittedAgreement =
            setting.lockSubmittedAgreement;
          this.settingsPayload.sendReminders = setting.sendReminders;
          this.settingsPayload.reminderMessage = setting.reminderMessage;
          this.settingsPayload.employeeInAppraisalProcess =
            setting.employeeInAppraisalProcess;
          this.settingsPayload.lineManagerInAppraisalProcess =
            setting.lineManagerInAppraisalProcess;

          this.reminderOptions.filter((option) => {
            if (option.name === setting.frequency) {
              this.selectReminder = option.id;
            }
            return {};
          });

          if (
            this.settingsPayload.editDocument !== "" ||
            this.settingsPayload.appraisailScoringPreference !== ""
            || this.settingsPayload.allowModeratorsSeeScore !== ""
          ) {
            this.disableBtn = false;
          }
            return {};
        });
      });
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performance",
        "accessGeneralSettings"
      );
      this.getGeneralSetting();
    } catch (error) {
      this.errorPage = true;
    }
  },
};
</script>

<style scoped>
.dashed_border {
  position: static;
  max-width: 90vw;
  height: 0px;
  left: 18px;
  top: 210px;
  border: 1px dashed #878e99;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 18px 0px;
}
.last_description_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #f15a29;
  margin: 5.5px 0px;
  cursor: pointer;
}
.description_text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #333333;
  margin: 5.5px 0px;
}
.button {
  width: 7.875rem;
  height: 2.75rem;
  padding: 12px, 15px, 12px, 15px;
}
.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 461px;
  height: 136px;
  left: 0px;
  top: 38px;
  background: #f7f7ff;
  border-radius: 5px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px;
}
.half {
  flex-basis: 50%;
}
.border-r-2 {
  border-right: 1px solid #878e99;
}
.text-rating {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #333333;
}
.lowest {
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #ea3c53;
}
.highest {
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #13b56a;
}
/* .rantings_height{
  height: 38px;
} */
.rankingtable {
  height: 397px;
  margin: 22px 0px 0px 0px;
}
.rankingtable_header {
  height: 33px;
  padding: 8px;
  font-size: 12px;
  font-weight: 900;
  line-height: 140%;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #878e99;
}
.rankingtable_row {
  margin: 4px 0px;
}
.rankingtable_row:nth-child(odd) {
  background-color: #f7f7ff;
}
.rankingtable_row:nth-child(even) {
  background-color: #ffffff;
}
.row_content {
  height: 40px;
  margin: 12px 0px;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 120%;
  color: #321c3b;
  letter-spacing: 0.015em;
}
</style>
